<template>
    <div class="mt-5">
        <div v-if="classroomsEnCours.length > 0 && !showAll">
            <div class="card card-custom flex-row justify-content-between align-items-center px-5">
                <h4 class="font-weight-bolder text-primary my-5">
                    Classrooms en cours
                    <span class="mx-2 text-muted"> ( {{ classroomsEnCours.length }} )</span>
                </h4>
            </div>
            <div class="row d-flex mt-2 displayWrap mt-5">
                <SessionCard v-for="classroom in classroomsEnCours" :key="classroom.id" :classroom="classroom"></SessionCard>
            </div>
        </div>

        <div v-if="classroomsAVenir.length > 0 && !showAll">
            <div class="card card-custom flex-row justify-content-between align-items-center px-5">
                <h4 class="font-weight-bolder text-primary my-5">
                    Classrooms à venir
                    <span class="mx-2 text-muted"> ( {{ classroomsAVenir.length }} )</span>
                </h4>
            </div>
            <div class="row d-flex mt-2 displayWrap mt-5">
                <SessionCard v-for="classroom in classroomsAVenir" :key="classroom.id" :classroom="classroom"></SessionCard>
            </div>
        </div>

        <div v-if="!showAll">
            <div class="card card-custom flex-row justify-content-between align-items-center px-5">
                <h4 class="font-weight-bolder text-primary my-5">
                    Classrooms terminées récemment
                    <span class="mx-2 text-muted"> ( {{ classroomsTerminees.length }} )</span>
                </h4>
                <b-button pill variant="outline-primary" @click="showAll = true">Voir tout</b-button>
            </div>
            <div v-if="classroomsTerminees.length > 0" class="row d-flex mt-2 displayWrap mt-5">
                <SessionCard v-for="classroom in classroomsTerminees" :key="classroom.id" :classroom="classroom"></SessionCard>
            </div>
            <div v-else class="card card-custom flex-column justify-content-center align-items-center py-5 mt-5">
                <p class="m-0">Vous n'avez aucune session récente à afficher.</p>
            </div>
        </div>

        <div v-if="showAll">
            <div class="card card-custom justify-content-between align-items-center px-5">
                <div class="d-flex w-100 my-5 align-items-center text-center">
                    <b-button @click="showAll = false" variant="outline-danger">
                        <span class="svg-icon svg-icon-md svg-icon-light-danger">
                            <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                        </span>
                        {{ mobile() ? '' : 'Retour' }}
                    </b-button>
                    <p class="m-0 h3" style="width: 90%">Liste des classrooms terminées</p>
                </div>
                <div class="d-flex justify-content-around w-100" :class="mobile() ? 'flex-column' : ''">
                    <!--Masquer si tuteur-->
                    <b-form-group
                            label="Nom de la classe :"
                            label-for="classeName"
                            class="mb-2 px-5"
                            :class="mobile() ? 'w-100' : 'w-25'"
                            invalid-feedback="Veuillez sélectionner une classe"
                            data-app
                    >
                        <b-form-select id="classeName" name="classeName" v-model="filter.classeName" :options="classeList"></b-form-select>
                    </b-form-group>
                    <b-form-group
                        v-if="!tuteur"
                        label="Tuteur :"
                        label-for="tuteur"
                        class="mb-2 px-5"
                        :class="mobile() ? 'w-100' : 'w-25'"
                        invalid-feedback="Veuillez sélectionner un tuteur"
                        data-app
                    >
                        <b-form-select id="tuteur" name="tuteyr" v-model="filter.tuteur" :options="tuteurList"></b-form-select>
                    </b-form-group>
                    <b-form-group
                            label="Date de début :"
                            label-for="date_deb"
                            class="mb-2 px-5"
                            :class="mobile() ? 'w-100' : 'w-25'"
                            invalid-feedback="Veuillez sélectionner une date de début"
                            data-app
                    >
                        <div class="d-flex justify-center align-items-center">
                            <VueDatePicker
                                    id="date_deb"
                                    v-model="filter.dateDeb"
                                    :visible-years-number="5"
                                    format="DD/MM/YYYY"
                                    placeholder="Début - Fin"
                                    range
                                    range-header-text="Du %d au %d"
                                    range-input-text="Du %d au %d"
                                    fullscreen-mobile
                            />
                            <i class="fas fa-times-circle ml-2" @click="filter.dateDeb = new Date()"></i>
                        </div>
                    </b-form-group>
                    <b-form-group
                            label="Date de fin :"
                            label-for="date_end"
                            class="mb-2 px-5"
                            :class="mobile() ? 'w-100' : 'w-25'"
                            invalid-feedback="Veuillez sélectionner une date de fin"
                            data-app
                    >
                        <div class="d-flex justify-center align-items-center">
                            <VueDatePicker
                                    id="date_deb"
                                    v-model="filter.dateEnd"
                                    :visible-years-number="5"
                                    format="DD/MM/YYYY"
                                    placeholder="Début - Fin"
                                    range
                                    range-header-text="Du %d au %d"
                                    range-input-text="Du %d au %d"
                                    fullscreen-mobile
                            />
                            <i class="fas fa-times-circle ml-2" @click="filter.dateEnd = new Date()"></i>
                        </div>
                    </b-form-group>
                </div>
                <v-data-table
                        id="tableClasse"
                        :style="'width: 100%; font-family: Poppins,Helvetica;'" :loading-text="'Chargement...'"
                        :locale="'fr-FR'"
                        :items-per-page="perPage" :page="currentPage" :headers="fields"
                        :items="allClassrooms"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        must-sort
                        responsive
                        :loading="isBusy"
                        style="vertical-align: middle; text-align: center"
                        class="mx-0 p-0"
                        :class="mobile ? 'mb-5 mt-0' : 'my-5'"
                        hide-default-footer
                        no-data-text="Aucune donnée à afficher"
                        no-results-text="Aucun résultat"
                >
                    <template v-slot:item.start_date="{ item }">
                        {{ formatDate(item.start_date) }}
                    </template>
                    <template v-slot:item.end_date="{ item }">
                        {{ formatDate(item.end_date) }}
                    </template>
                    <template v-slot:item.tuteur.id="{ item }">
                        {{ item.tuteur.firstname }} {{ item.tuteur.lastname.toUpperCase() }}
                    </template>
                    <template v-slot:item.nb_stagiaires="{ item }">
                        {{ item.nb_stagiaires }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <router-link :to="{ name: '' }" replace>
                            <button class="btn btn-icon btn-circle btn-light btn-sm"
                                    v-b-tooltip.hover.left="'Voir les détails'">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                    <inline-svg src="/media/svg/icons/General/Visible.svg"/>
                                </span>
                            </button>
                        </router-link>
                    </template>
                </v-data-table>
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="allSessions"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import {LIST_CLASSROOMS} from "../../core/services/store/api/classroom.service";
import moment from "moment/moment";
// import VSelect from "@alfsnd/vue-bootstrap-select";

export default {
    name: "Classrooms",
    components: {
        SessionCard: () => import('../content/components/SessionCard.vue'),
        // VSelect: VSelect
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            loading: true,
            showAll: false,
            isBusy: true,
            sortBy: 'end_date',
            sortDesc: true,
            classroomsEnCours: [],
            classroomsAVenir: [],
            classroomsTerminees: [],
            allClassrooms: [],
            tuteurList: [{value: '', text: 'Tous les tuteurs'}],
            classeList: [{value: '', text: 'Toutes les classes'}],
            filter: {
                tuteur: '',
                classeName: '',
                dateDeb: new Date(),
                dateEnd: new Date()
            },
            tuteur: false,
        }
    },
    async mounted() {
        await this.$store.dispatch(LIST_CLASSROOMS, 'true').then(response => {
            this.classroomsEnCours = response.data.filter(classroom => new Date(classroom.start_date) <= new Date() && new Date(classroom.end_date) >= new Date());
            this.classroomsEnCours.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

            this.classroomsAVenir = response.data.filter(classroom => new Date(classroom.start_date) > new Date());
            this.classroomsAVenir.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

            this.classroomsTerminees = response.data.filter(classroom => new Date(classroom.end_date) < new Date());
            this.classroomsTerminees.sort((a, b) => new Date(a.end_date) - new Date(b.end_date));
        });
        this.loading = false;
        this.isTuteur();
    },
    methods: {
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        mobile() {
            return window.innerWidth < 768;
        },
        classeNameFilter(value) {
            this.currentPage = 1;
            if (!this.filter.classeName || this.filter.classeName === '') {
                return true;
            }
            return value === this.filter.classeName;
        },
        tuteurFilter(value) {
            this.currentPage = 1;
            if (!this.filter.tuteur || this.filter.tuteur === '') {
                return true;
            }
            return value === this.filter.tuteur;
        },
        dateDebFilter(value) {
            if (!value) {
                return true
            }

            if (!this.filter.dateDeb.start) {
                return true
            }

            value = new Date(value)
            let start = new Date(this.filter.dateDeb.start)
            let end = new Date(this.filter.dateDeb.end)
            return value >= start && value <= end
        },
        dateEndFilter(value) {
            if (!value) {
                return true
            }
            if (!this.filter.dateEnd.start) {
                return true
            }
            value = new Date(value)
            let start = new Date(this.filter.dateEnd.start)
            let end = new Date(this.filter.dateEnd.end)
            return value >= start && value <= end
        },
        isTuteur() {
            if(this.$store.getters.currentUser.rights.academy.length > 0){
                this.$store.getters.currentUser.rights.academy.forEach(right => {
                    if(right.right_id === 1){
                        this.tuteur = true;
                    }
                })
            }
        }
    },
    watch: {
        showAll() {
            if (this.showAll) {
                this.$store.dispatch(LIST_CLASSROOMS, 'false').then(response => {
                    this.allClassrooms = response.data;
                    this.allClassrooms.forEach(classroom => {
                        //push if not exist
                        if (!this.tuteurList.some(tuteur => tuteur.value === classroom.tuteur.id)) {
                            this.tuteurList.push({
                                value: classroom.tuteur.id,
                                text: classroom.tuteur.firstname + ' ' + classroom.tuteur.lastname.toUpperCase()
                            });
                        }
                    });

                    this.allClassrooms.forEach(classroom => {
                        //push if not exist
                        if (!this.classeList.some(classe => classe.value === classroom.classe.title)) {
                            this.classeList.push({
                                value: classroom.classe.title,
                                text: classroom.classe.title
                            });
                        }
                    });
                    this.isBusy = false;
                });
            }
        }
    },
    computed: {
        rows() {
            return this.allClassrooms.length;
        },
        fields() {
            return [
                {value: 'classe.title', text: 'Nom de la classe', filter: this.classeNameFilter},
                {value: 'start_date', text: 'Date de début', sortable: true, filter: this.dateDebFilter},
                {value: 'end_date', text: 'Date de fin', sortable: true, filter: this.dateEndFilter},
                {value: 'tuteur.id', text: 'Tuteur', sortable: true, filter: this.tuteurFilter},
                {
                    value: 'nb_stagiaires',
                    text: 'Nombre de stagiaires',
                    sortable: true,
                },
                {value: 'action', text: '', sortable: false},
            ]
        },
    }
}
</script>